import { useSelector } from "react-redux";
import arrowbar from "../../assets/Images/arrow-bar.png";
import { RootState } from "../../redux/Store/store";
import { useId } from "react";
import { ScoreSection } from ".";
const FinalScoreCard = ({
  togglePanel,
  openPanels,
}: {
  togglePanel: (panel: string) => void;
  openPanels: any;
}) => {
  const finalScoreId = useId();

  const { completedTestData } = useSelector(
    (state: RootState) => state.root.startFullTest
  );

  const questionData = completedTestData?.additionalProps?.overallresult;
  const {
    overallReadingScaledScore,
    overallMathScaledScore,
    overallFinaleScaledScore,
  } = questionData || {};

  const finalScores: ScoreSection = {
    title: "Final Score",
    totalScore: `${overallFinaleScaledScore || "0"} / 1600`,
    modules: [
      {
        name: "Reading and Writing",
        score: overallReadingScaledScore,
        total: "800",
      },
      { name: "Math", score: overallMathScaledScore, total: "800" },
    ],
  };

  return (
    <div
      style={{ boxShadow: "0px 0px 10px 5px #20202026" }}
      className={`rounded-xl p-4 flex-grow ${
        openPanels?.finalScore ? "h-full" : "h-fit"
      }`}
    >
      <div
        className={`${
          openPanels?.finalScore ? "border-b border-[#E1E1E1] pb-3 mb-4 " : ""
        } flex items-center justify-between text-appPrimaryColor text-xl transition-all duration-300 ease-in-out`}
      >
        <div className="flex items-center space-x-2">
          <button
            onClick={() => togglePanel("finalScore")}
            className=" hover:bg-gray-100 rounded"
          >
            <img
              src={arrowbar}
              alt="arrowbar"
              className={`transform transition-transform font-medium ${
                openPanels.finalScore ? "rotate-180" : ""
              }`}
            />
          </button>
          <span className="font-medium">Final Score</span>
        </div>
        <span className="text-blue-500 font-medium">
          {completedTestData?.isCustomTest ? "-" : finalScores.totalScore}
        </span>
      </div>
      {openPanels?.finalScore && (
        <div className="space-y-3">
          {finalScores.modules.map((module: any, idx: number) => (
            <div
              key={finalScoreId}
              className="flex justify-between items-center text-lg"
            >
              <span>{module.name}</span>
              {completedTestData?.isCustomTest ? (
                <span> - </span>
              ) : (
                <span>
                  {module.score || "0"} / {module.total}
                </span>
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default FinalScoreCard;
