import { useSelector } from "react-redux";
import { ScoreSection } from ".";
import arrowbar from "../../assets/Images/arrow-bar.png";
import { RootState } from "../../redux/Store/store";
import { useId } from "react";

const RawScoreCard = ({
  togglePanel,
  openPanels,
}: {
  togglePanel: (panel: string) => void;
  openPanels: any;
}) => {

  const { completedTestData } = useSelector((state: RootState) => state.root.startFullTest);
  const overallResults = completedTestData?.additionalProps?.overallresult

  const lengthOfArray = (...rest: any) => {
    return rest.flat().length;
  };
  const totalCorrectAnswers = lengthOfArray(
    overallResults?.readingModuleOneCorrectAnswers,
    overallResults?.readingModuleTwoCorrectAnswers,
    overallResults?.mathsModuleOneCorrectAnswers,
    overallResults?.mathsModuleTwoCorrectAnswers
  );

  const totalQuestions = lengthOfArray(
    overallResults?.readingModuleOneOverallResult,
    overallResults?.readingModuleTwoOverallResult,
    overallResults?.mathsModuleOneOverallResult,
    overallResults?.mathsModuleTwoOverallResult
  );

  const getModuleData = (modulePrefix: string, overallResults: any,totalQuestions:number) => {
    const moduleOneCorrect =
      overallResults?.[`${modulePrefix}ModuleOneCorrectAnswers`]?.length || 0;
    const moduleTwoCorrect =
      overallResults?.[`${modulePrefix}ModuleTwoCorrectAnswers`]?.length || 0;
    const moduleOneTotal =
      overallResults?.[`${modulePrefix}ModuleOneOverallResult`]?.length || 0;
    const moduleTwoTotal =
      overallResults?.[`${modulePrefix}ModuleTwoOverallResult`]?.length || 0;

    return {
      correctAnswers: moduleOneCorrect + moduleTwoCorrect,
      totalAnswers: moduleOneTotal + moduleTwoTotal || totalQuestions,
      module1CorrectAnswers: moduleOneCorrect,
      module2CorrectAnswers: moduleTwoCorrect,
      module1TotalScore: moduleOneTotal,
      module2TotalScore: moduleTwoTotal,
    };
  };

  const modulesData = [
    {
      id: 1,
      name: "Reading and Writing",
      ...getModuleData("reading", overallResults,54),
    },
    {
      id: 2,
      name: "Math",
      ...getModuleData("maths", overallResults,44),
    },
  ];

  const rawScoreId = useId();
  const rawScores: ScoreSection = {
    title: "Raw Score",
    totalScore: `${totalCorrectAnswers || "0"}/${totalQuestions || "0"}`,
    modules: modulesData?.map((moduleData) => ({
      name: moduleData?.name,
      score: `${moduleData?.correctAnswers || "0"}/${
        moduleData?.totalAnswers || "0"
      }`,
      subModules: [
        {
          name: "Module 1",
          score: `${moduleData?.module1CorrectAnswers || "0"}/${
            moduleData?.module1TotalScore || "0"
          }`,
        },
        {
          name: "Module 2",
          score: `${moduleData?.module2CorrectAnswers || "0"}/${
            moduleData?.module2TotalScore || "0"
          }`,
        },
      ],
    })),
  };

  return (
    <div
      style={{ boxShadow: "0px 0px 10px 5px #20202026" }}
      className={`rounded-xl p-4 basis-[50%] ${
        openPanels?.rawScore ? "h-full" : "h-fit"
      }`}
    >
      <div
        className={`${
          openPanels?.rawScore ? "border-b border-[#E1E1E1] pb-3 mb-4" : ""
        } flex items-center justify-between transition-all duration-300 ease-in-out`}
      >
        <div className="flex items-center space-x-2 text-appPrimaryColor font-medium text-xl">
          <button
            onClick={() => togglePanel("rawScore")}
            className=" hover:bg-gray-100 rounded"
          >
            <img
              src={arrowbar}
              alt="arrowbar"
              className={`transform transition-transform font-medium ${
                openPanels.rawScore ? "rotate-180" : ""
              }`}
            />
          </button>
          <span>Raw Score</span>
        </div>
        <span className="text-appPrimaryColor text-xl font-medium">
          {rawScores?.totalScore}
        </span>
      </div>
      {openPanels?.rawScore && (
        <div className="flex items-center justify-between gap-x-6">
          {rawScores.modules.map((module: any, idx: number) => (
            <div key={rawScoreId} className="flex-grow">
              <div className="flex justify-between items-center pb-3 text-lg">
                <span>{module.name}</span>
                <span>{module.score}</span>
              </div>
              <div className="space-y-1">
                {module.subModules?.map((subModule: any, subIdx: number) => (
                  <div
                    key={rawScoreId}
                    className="flex justify-between items-center text-lg text-gray-600 pb-2"
                  >
                    <span>{subModule.name}</span>
                    <span>{subModule.score}</span>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default RawScoreCard;
