import { IoCloseSharp } from 'react-icons/io5'
import { useDispatch } from 'react-redux';
import { closeDialog } from '../../redux/Slices/dialogSlice';

const TestTypeDetails = () => {
    const dispatch = useDispatch();
  return (
    <div>
        <button className='absolute top-3 right-3' onClick={() => dispatch(closeDialog())}><IoCloseSharp className='w-6 h-6' color='#646464' /></button>
        <div className='flex flex-col font-gully gap-3'>
            <h1 className='text-[21px] font-medium text-appPrimaryColor'>Standard Type</h1>
            <p className='text-lg font-normal leading-[21.6px] text-[#202020]'>The Standard Type SAT Test is designed to replicate the SAT experience as closely as possible, following the Bluebook format.</p>
        </div>
        <div className='flex flex-col font-gully gap-3 mt-6'>
            <h1 className='text-[21px] font-medium text-appPrimaryColor'>Custom Type</h1>
            <p className='text-lg font-normal leading-[21.6px] text-[#202020]'>The Custom Type SAT Test enables users to personalize aspects such as difficulty, time allocation, and breaks to enhance their test preparation experience.</p>
        </div>
    </div>
  )
}

export default TestTypeDetails